<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
export default {
  page: {
    title: "แก้ไขข้อมูลอนุมัติโอนรถข้ามสาขา",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "แก้ไขข้อมูลอนุมัติโอนรถข้ามสาขา",
      items: [
        {
          text: "คลังสินค้า",
          active: true,
        },
        {
          text: "อนุมัติโอนรถข้ามสาขา",
          active: false,
          href: "/approve-vehicle-transfer",
        },
        {
          text: "แก้ไขข้อมูลอนุมัติโอนรถข้ามสาขา",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsProduct: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      optionBranch: [],
      rowsDetail: [],
      loading: false,
      userBranchAccessId: "",
      interiorColor: "",
      exteriorColor: "",
      branchApproveVehicleTransferDetail: [],

      vehicle: [],
      userBranchId: "",
      stock: "",
      supId: "",
      transferCost: "",

      branchSelect: "",
      branchStock: "",

      poItem: {
        prodId: "",
        nameTh: "",
        prodCode: "",
        pricePerOne: "",
        amount: 0,
        poTotalPrice: "",
        poDcPer: 0,
        poDcPrice: "",
        poNetPrice: "",
        prodNameTh: "",
        vehicleId: "",
        cost: "",
      },

      poDate: "",
      status: "",
      textNote: "",
      chkTransfer: [],

      poVatPer: "",
      poTotalPrice: "",
      poDcPer: 0,
      poDcPrice: 0,
      poNetPrice: "",
      poVatPrice: "",
      poGrandTotalPrice: "",

      rowsStock: [],
      optionsPO: [],
      poCode: "",

      nameState: null,
      apvCode: "",
      apvDate: "",
      note: "",
      cost: "",

      transferdId: "",

      userBranch: "",
      prodId: "",
      filterOn: [],
      rowsPO: [],
      rowsSuppliers: [],
      sortBy: "age",
      sortDesc: false,
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsPO.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranchAccessId = user.branchId;
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsPO.total;
    if (this.$route.params.apvId) {
      this.getDataDetail();
      //   this.getDetailProd();
    }
  },
  created() {
    // this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    numberFormats(x) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(x);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getDataDetail: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/approve-vehicle-transfer/show", {
          params: {
            apvId: atob(this.$route.params.apvId),
          },
        })
        .then((response) => {
          this.userBranchId = {
            branchId: response.data.branchApproveVehicleTransfer.branchId,
            nameTh: response.data.branchApproveVehicleTransfer.branchName,
          };
          this.apvCode = response.data.branchApproveVehicleTransfer.apvCode;
          this.apvDate = response.data.branchApproveVehicleTransfer.apvDate;
          this.note = response.data.branchApproveVehicleTransfer.note;

          this.branchApproveVehicleTransferDetail =
            response.data.branchApproveVehicleTransferDetail;
          this.getBrancApproveTransfer();
          this.calculatePo(this.branchApproveVehicleTransferDetail);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDetailProd: function(transfer) {
      if (transfer.transferId != null) {
        this.loading = true;
        useNetw
          .get("api/approve-vehicle-transfer/branch-vehicle-transfer-product", {
            params: {
              transferId: transfer.transferId,
              // transferId: atob(this.$route.params.transferId),
            },
          })
          .then((response) => {
            this.rowsDetail = response.data.data;
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            // this.$router.push("/login");
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    getBrancApproveTransfer: function() {
      useNetw
        .get("api/approve-vehicle-transfer/branch-vehicle-transfer", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.userBranchId.branchId,
            // this.userBranchAccessId,
            // this.userBranchAccessId != null
            //   ? this.userBranchId.branchId
            //   : undefined,
          },
        })
        .then((response) => {
          this.rowsProduct = response.data.data;
          // this.rowsDetail = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {});
    },
    calculatePo(element) {
      var poTotalPrice = 0;
      element.forEach((data) => {
        data.vehicle.forEach((item) => {
          //    poTotalPrice.push(parseFloat(item.cost))
          poTotalPrice += parseInt(item.cost);
        });
      });
      this.poTotalPrice += poTotalPrice;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div
              class="col-12 badge"
              style="background-color: #26B99A"
              v-if="this.status === 'APV'"
            >
              <h4 class="mt-2 text-white">
                โอนรถข้ามสาขาแล้ว
              </h4>
            </div>
            <div
              class="col-12 badge"
              style="background-color: #50a5f1"
              v-if="this.status === 'SUBMIT'"
            >
              <h4 class="mt-2 text-white">
                รอยืนยันการโอนรถข้ามสาขา
              </h4>
            </div>
            <div
              class="col-12 badge"
              style="background-color: #e74646"
              v-if="this.status === 'CAN'"
            >
              <h4 class="mt-2 text-white">
                ยกเลิกแล้ว
              </h4>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            จากสาขา:
                            <multiselect
                              v-model="userBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                              disabled
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            รหัสโอนรถ:
                            <b-form-input
                              v-model="apvCode"
                              type="search"
                              disabled
                              class="form-control"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            วันที่โอน:
                            <date-picker
                              v-model="apvDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                              disabled
                            ></date-picker>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            หมายเหตุ:
                            <b-form-textarea
                              v-model="note"
                              rows="3"
                              disabled
                              max-rows="6"
                            ></b-form-textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <p class="card-title-desc">รายการที่อนุมัติโอนรถข้ามสาขา</p>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">รหัสสินค้า</th>
                      <th scope="col">ชื่อสินค้า</th>
                      <th scope="col">สีภายนอก</th>
                      <th scope="col">สีภายใน</th>
                      <!-- <th scope="col">รหัสรุ่นรถ</th>
                      <th scope="col">ชื่อรุ่นรถ</th> -->
                      <th scope="col">จากคลัง</th>
                      <th scope="col">ไปยังคลัง</th>
                      <!-- <th scope="col">ราคา</th> -->
                      <th scope="col">ราคาต้นทุน</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(data, index) in branchApproveVehicleTransferDetail"
                    :key="index"
                  >
                    <!-- <th colspan="9" style="background: #d5d5d5;">
                      <div class="mb-0">
                        รหัสโอน : {{ data.transferCode }} 
                      </div>
                      <div>
                          จากคลัง : {{ data.branchName }} ไปยังคลัง : {{ data.toBranchName }}
                      </div>
                      <div class="mb-0">วันที่โอน : {{ data.transferDate }}</div>
                    </th> -->
                    <th colspan="10" style="background: #d5d5d5;">
                      <div class="mb-0">
                        รหัสโอน: {{ data.transferCode }} จากคลัง:
                        {{ data.branchName }} ไปยังคลัง:
                        {{ data.toBranchName }} วันที่โอน:
                        {{ data.transferDate }}
                        ผู้อนุมัติ: {{ data.createdBy }}
                      </div>
                    </th>
                    <tr v-if="data.vehicle == ''">
                      <td class="text-center" colspan="10">
                        <h5>ไม่พบรายการสินค้า</h5>
                      </td>
                    </tr>
                    <tr v-for="(element, index) in data.vehicle" :key="index">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>{{ element.prodCode }}</td>
                      <td>{{ element.productNameTh }}</td>
                      <td>
                        {{
                          element.exteriorColorNameTh != null
                            ? poItem.exteriorColorNameTh
                            : "-"
                        }}
                      </td>
                      <td>
                        {{
                          element.interiorColorNameTh != null
                            ? poItem.interiorColorNameTh
                            : "-"
                        }}
                      </td>
                      <!-- <td>{{ element.modelCode }}</td> -->
                      <!-- <td>{{ element.modelNameTh }}</td> -->
                      <td>{{ element.fromStockName }}</td>
                      <td>{{ element.toStockName }}</td>
                      <!-- <td class="">
                        <input
                          v-model="element.price"
                          class="form-control text-end number"
                          disabled
                          type="number"
                        />
                      </td> -->
                      <td class="text-end">
                        <input
                          disabled
                          v-model="element.cost"
                          class="form-control text-end number"
                        />
                      </td>
                      <td v-if="status == 'DFT'">
                        <ul class="list-inline mb-0 action">
                          <li class="list-inline-item">
                            <a
                              class="px-2 text-primary"
                              v-b-tooltip.hover
                              title="Edit"
                              @click="showModal(poItem)"
                            >
                              <i class="uil uil-pen font-size-18"></i>
                            </a>
                            <a
                              class="px-2 text-danger"
                              v-b-tooltip.hover
                              title="Delete"
                              @click="alert(poItem, index)"
                            >
                              <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <div class="card-body">
                      <h4 class="card-title">
                        <h4>
                          <strong>สรุปยอดอนุมัติโอนรถข้ามสาขา</strong>
                        </h4>
                      </h4>
                      <hr />
                      <div class="row">
                        <div class="col-md-2 col-sm-3">
                          รายละเอียดการอนุมัติโอนรถข้ามสาขา
                        </div>
                        <div class="col-md-2 col-sm-3 col-6">
                          <div style="display: inline-block">
                            <div class="text-end">
                              ยอดรวมค่ารถ : <br />
                              ส่วนลดรวม(%) : <br />
                              ส่วนลดรวม(บาท) : <br />
                              จำนวนเงิน : <br />
                              ภาษีรวม(%) : <br />
                              ภาษีรวม(บาท) : <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-5 col-4 text-end">
                          <strong>
                            {{ this.numberFormats(this.poTotalPrice) }} <br />
                            {{ this.poDcPer }}<br />
                            {{ this.numberFormats(this.poDcPrice) }} <br />
                            {{ this.numberFormats(this.poNetPrice) }} <br />
                            {{ this.numberFormats(this.poVatPer) }} <br />
                            {{ this.numberFormats(this.poVatPrice) }}</strong
                          >
                        </div>
                        <div class="col-md-1 col-sm-2 col-2 text-end">
                          บาท<br />
                          %<br />
                          บาท <br />
                          บาท <br />
                          % <br />
                          บาท
                        </div>
                        <!-- มูลค่ารวม -->
                        <div
                          class="col-md-5 col-sm-5 bg-dark text-white m-0 row align-items-center"
                          style="border-radius:10px;margin:auto;justify "
                        >
                          <div class="row align-items-center m-0">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-md-3">มูลค่ารวม :</div>
                                <div class="col-6 font-size-24 text-center">
                                  <strong>{{
                                    Number(this.poTotalPrice).toLocaleString()
                                  }}</strong>
                                </div>
                                <div class="col-3">บาท</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
